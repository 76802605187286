import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'List',
    component: function () {
      return import('../components/List.vue')
    }
  },
  {
    path: '/report-missing',
    name: 'Report Missing',
    component: function () {
      return import('../components/Report.vue')
    }
  },
  {
    path: '/management',
    name: 'Management',
    component: function () {
      return import('../components/Management.vue')
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'بيانات مفقودي حرب 15 ابريل';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
export default router
